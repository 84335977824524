<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="main-heading">
                    <h1>Transform the Lives of 100 Million People and Animals </h1>
                </div>
                <div class="content-section center">
                    <h2>A Dream of Global Change</h2>
                    <p>CheapComforts is driven by an ambitious dream: to change the lives of 100 million people and
                        animals worldwide. By allocating 7% of our net income to this mission, we aim to do more than
                        just donate; we want to transform lives, heal hearts, and offer hope where it is needed most.
                        With the direct effort of our team, without intermediaries or external organizations,
                        CheapComforts is committed to working side by side with the communities and beings that suffer
                        the most.</p>

                    <p><span class="bold">Our goal is clear:</span> to create an impact so vast and deep that it reaches
                        every corner of the
                        world. From people facing extreme poverty to animals struggling to survive, our purpose is to
                        leave a lasting footprint that inspires real change. This report is a plan to bring our vision
                        to reality and ensure that millions of lives are touched by hope.
                    </p>

                    <h2>A Measurable Impact: <span class="nobold">Reaching 100 Million Beneficiaries</span></h2>
                    <p><span class="bold">Objective:</span>Over the coming years, we aim to reach 100 million people and
                        animals through our
                        initiatives. It’s not just about numbers; it’s about millions of stories that will change
                        forever.
                    </p>
                    <h4>Imagine:</h4>
                    <ul class="mission-list">
                        <li>Families with food on the table, thanks to our social support programs.</li>
                        <li>Animals running free again, healthy and safe from cruelty.</li>
                        <li>Entire communities thriving, thanks to infrastructure that provides them with clean water,
                            education, and dignity.</li>
                    </ul>

                    <h2>Success Metrics:
                    </h2>
                    <ul class="mission-list">
                        <li><span class="bold">People impacted:</span> We aim to improve the lives of at least 50
                            million people through food
                            assistance, educational programs, and healthcare initiatives, managed by the CheapComforts
                            team on the ground.</li>
                        <li><span class="bold">Animals protected and rescued:</span> CheapComforts seeks to care for and
                            save 50 million animals
                            that are in danger, sick, or abandoned. Through rescue campaigns and shelters run by our
                            collaborators, our goal is to ensure that millions of animals can live with the dignity and
                            protection they deserve.</li>
                        <li><span class="bold"> Projects in vulnerable communities:</span> In the most underserved
                            countries, CheapComforts is
                            committed to developing basic infrastructure: from clean water wells to schools and health
                            centers. Our goal is to impact millions of people in communities that lack access to
                            essential resources. </li>
                        <li> <span class="bold">Environmental sustainability initiatives:</span> We recognize that the
                            planet's health is
                            fundamental to the well-being of all. That’s why we include environmental restoration
                            projects, such as reforesting degraded areas and creating green spaces in communities. Our
                            goal is to plant 50 million trees worldwide.</li>
                    </ul>
                    <h2>Commitment to the Environment: <span class="nobold">Caring for Our Planet</span>
                    </h2>
                    <p>CheapComforts understands that the health of the planet is crucial for the well-being of people
                        and animals. That’s why we integrate an environmental approach into our social responsibility
                        initiative.

                    </p>
                    <h2>Environmental initiatives:
                    </h2>
                    <ul class="mission-list">
                        <li> <span class="bold">Reforestation and conservation:</span> We will implement reforestation
                            programs in degraded areas
                            and promote the conservation of natural habitats. Our goal is to plant 50 million trees in
                            the coming years, restoring ecosystems and providing a home for endangered species. </li>
                        <li> <span class="bold">Environmental education: </span> We will implement educational programs
                            in vulnerable communities to
                            raise awareness about the importance of sustainability and environmental care. By empowering
                            communities with knowledge, we help build a greener, more sustainable future. </li>
                        <li> <span class="bold">Renewable energy projects: </span> We will launch projects that provide
                            access to clean and
                            renewable energy in underserved communities, such as solar panels in schools and health
                            centers, reducing dependence on fossil fuels and promoting a more sustainable lifestyle.
                        </li>
                        <li> <span class="bold">Plastic waste: </span>Plastic waste collection on beaches and natural
                            environments:</li>
                        <li> <span class="bold">Beach cleanups: </span> We will organize cleanup events on beaches and
                            natural areas, where our
                            collaborators and volunteers will work together to collect plastic waste and other litter.
                            Each event will be designed to engage the community, fostering a sense of responsibility and
                            care for the environment. </li>
                        <li> <span class="bold">Training and awareness: </span> During the cleanup events, we will offer
                            workshops on the importance
                            of reducing plastic use and recycling. In this way, we not only help clean up but also
                            educate participants about the impact of plastic on our oceans and ecosystems.</li>
                    </ul>
                    <h2>Heartwarming Stories: <span class="nobold">Every Life Matters</span>
                    </h2>
                    <p>Every number has a face, a story. They are not statistics; they are human and animal lives that
                        we want to save and improve. We want to share these stories with the world so that our community
                        can see the tangible, emotional, and real change we are achieving together. Stories of parents
                        who, for the first time, can bring clean water to their children; of children who can run and
                        play with their rescued animals, healthy and happy; of communities that now have access to
                        education and can imagine a brighter future.</p>
                    <h2>Communication:</h2>
                    <ul class="mission-list">
                        <li> <span class="bold">Emotional testimonials: </span> We will publish videos regularly from
                            our collaborators and from
                            those whose lives we have changed, showing their gratitude and joy. The employees on the
                            ground will become our eyes and ears, sharing stories that move and inspire hope. </li>
                        <li> <span class="bold">Visual impact: </span> Through images and videos taken directly by the
                            CheapComforts team, we will
                            show real-time progress. Each video will be a window into the smiles, hugs, and moments of
                            relief we bring to people and animals through our initiative. </li>
                        <li><span class="bold">Stories on our blog:</span> We will share detailed, personal stories on
                            social media from those who
                            have benefited. Each month, we will highlight a different story, showing how every project,
                            no matter how small it may seem, can change millions of lives.</li>
                    </ul>
                    <h2>Engaging Our Customers: <span class="nobold"> Together We Can Achieve More</span></h2>
                    <p>We don’t want to make this change alone. We know that our community of customers is made up of
                        people with a strong sense of compassion and a desire to make a difference. That’s why we invite
                        them to be part of this mission for massive impact.</p>
                    <h2>Customer participation proposal:</h2>
                    <ul class="mission-list">
                        <li> <span class="bold">Additional donations: </span> With each purchase, customers will have
                            the option to round up their
                            total or add an extra donation. Every penny counts when it comes to transforming millions of
                            lives. We want our customers not only to buy products but also to become agents of global
                            change.</li>
                        <li> <span class="bold">Recognition and gratitude: </span> CheapComforts will give special
                            thanks to every customer who
                            participates in this cause. This could include personalized messages, donation certificates,
                            and discounts on future purchases as a token of appreciation for their support.
                        </li>
                        <li> <span class="bold">Total transparency: </span> We will publish quarterly reports detailing
                            how every donation, every
                            action, and every effort is contributing to our goal of impacting 100 million living beings.
                            This will not only build trust in our brand but also a sense of pride and belonging in our
                            customer community.</li>
                    </ul>
                    <h2>Conclusion:<span class="nobold"> The Power of a Shared Dream</span></h2>
                    <p>We stand before the possibility of doing something extraordinary. CheapComforts is not just a
                        brand; it is a driving force for change, a platform that can touch the hearts of 100 million
                        living beings. With the passion of our team, the commitment of our customers, and the drive of
                        our vision, we believe this dream is achievable.</p>
                    <p>We are not just talking about numbers. Every person, every animal, every community we help will
                        represent a story of struggle, overcoming, and hope. This is our commitment: to change millions
                        of lives forever. And together, we will make it happen.</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'about_us',
    components: {
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 5px !important;
}



.mission-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.bold {
    font-weight: bold;
}

.nobold {
    font-weight: normal;
}
</style>